import logo from './logo.svg';
import './App.css';
import './css/style.css';
import { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route,Link } from "react-router-dom";
import "./vendor/font-awesome/css/all.min.css";
import "./vendor/bootstrap-icons/bootstrap-icons.css";
import "./vendor/tiny-slider/tiny-slider.css";
import "./vendor/glightbox/css/glightbox.css";
import Main from "./modules/Main";
import Sidebar from "./modules/Sidebar";
import Loginform from './modules/Loginform';
import Plans from "./modules/Plans";
import Custom from "./modules/Custom";
import {ThemeProvider,createTheme} from '@mui/material';
import { initializeApp } from "firebase/app";

if(localStorage.getItem("MAIN_URL") == null)
localStorage.setItem("MAIN_URL","https://api.eureca.ao/v0.1");

const firebaseConfig = {
  apiKey: "AIzaSyAZoclRzoTI7vnPnJJQkJHFvluBbWRtBy8",
  authDomain: "eureca-7f61b.firebaseapp.com",
  databaseURL: "https://eureca-7f61b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "eureca-7f61b",
  storageBucket: "eureca-7f61b.appspot.com",
  messagingSenderId: "645746760374",
  appId: "1:645746760374:web:e5b49feb3850a9c9236417",
  measurementId: "G-SN90THBVZ2"
};

// Temas personalizados
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#000000', // Preto
    },
    secondary: {
      main: '#ffffff', // Branco
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#b3b3b3',
    },
  },
});

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2', // Azul padrão do Material-UI
    },
    secondary: {
      main: '#dc004e', // Rosa padrão do Material-UI
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#757575',
    },
  },
});

const THEME_KEY = "theme";
const getPreferredTheme = () => {
  const storedTheme = localStorage.getItem(THEME_KEY);
  if (storedTheme === "dark") return true;
  if (storedTheme === "light") return false;
  
  // If no theme is stored, use system preference
  return window.matchMedia("(prefers-color-scheme: dark)").matches;
};

function App() {
  
  const app = initializeApp(firebaseConfig);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(getPreferredTheme);
  // Função para alternar entre temas
  const toggleTheme = () => {
    setDarkMode(!darkMode);
    localStorage.setItem(THEME_KEY, !darkMode ? "dark" : "light");
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {localStorage.getItem("token") == undefined?<Loginform/>: <BrowserRouter>
      <Sidebar darkMode={darkMode} toggleTheme={toggleTheme} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>
      <Routes>
          <Route path="/" element={<Main darkMode={darkMode} />} exact></Route>
          <Route path="/admin/plans" element={<Plans />} exact></Route>
          <Route path="/admin/custom" element={<Custom />} exact></Route>
      </Routes>
    </BrowserRouter>}
    </ThemeProvider>
  );
}

export default App;
