import {useEffect,useState} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Box,
  Divider,
  TextField,List,ListItem,ListItemText
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Network from '../network/networkRequests';
import CircularProgress from '@mui/material/CircularProgress';
import { getDatabase, ref, set,get,onValue,child,update} from "firebase/database";
import {getDecodedToken} from './util';

const roles = new Map([
  ["USER_PAYMENT_PENDING", "Aguardando"],
  ["USER_ANALYSIS_PENDING", "Análise"],
  ["SUBSCRIBED_BASE", "Básico"],
  ["SUBSCRIBED_POPULAR", "Popular"],
  ["SUBSCRIBED_MASTER", "Visionário"]
]);

function DetailDialog({ open, handleClose, appID }) {

  const base_user_info = getDecodedToken();

  const db = getDatabase();
  const [message, setMessage] = useState('');
  const firebaseSavePath = 'user_info/' + appID?.id;

  const [userDataSingle, setUserData ] =  useState({});
  const [userLite, setUserLite ] =  useState({});
  const [loading, setloading ] =  useState(false);
  
  useEffect(()=>{
    getUsers();
    onValue(ref(db, firebaseSavePath), (snapshot) => {
      const val = snapshot.val();
      setUserLite(val);
    });
  },[]);

  async function getUsers(){
    setloading(true);
    const getAplicationData = await Network.sendRequest('get', '/aplication/'+appID?.id, {});
    setUserData(getAplicationData.data);
    setloading(false);
  }

  const aproveInvite = async () =>{
    setloading(true);
    const getAplicationData = await Network.sendRequest('post', '/application/approve/'+appID?.id, {});
    if(getAplicationData.data){
      set(ref(db, firebaseSavePath),{"nt":userLite?.nt+1,'role':"USER_PAYMENT_PENDING"});
      handleClose()
    }
    setloading(false);
  }

  const handleSendMessage = async ()  => {
    var data = {
      "MsgFrom":base_user_info?.email,
      "MsgTo": userDataSingle?.user_id,
      "isOpen": "0",
      "archived": "0",
      "message":message,
      "idMessenger": "EURECA",
      "ref": "eureca submition"
    }

    update(ref(db,  'user_info/'+appID?.id), { nt: userLite?.nt+1 });
    await Network.sendRequest('post', '/message/admin/sendmessage', data);
    setMessage('');
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '12px', // Bordas arredondadas
          padding: '16px', // Espaçamento interno
        },
      }}
    >
      <DialogTitle sx={{ fontSize: '24px', fontWeight: '600', color: '#1c1c1e' }} className='d-flex justify-content-between'>
       <div> Detalhes da Aplicação </div><div>
        {loading? <CircularProgress size="30px" />: <Chip label={<Typography  sx={{ color: '#1c1c1e', fontWeight: '500'}}>{roles.get(userDataSingle?.role)}</Typography>} />  }
        </div>
      </DialogTitle>
      <DialogContent dividers>
      <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ color: '#1c1c1e', fontWeight: '500', mb: 1 }}>Identidade</Typography>
          <div className='d-flex justify-content-start gap-4'>
            <Box sx={{ display: 'flex', gap: 2, mb: 2, flexDirection:"column" }}>
             <img src={appID?.profileImage} alt=""   style={{ width: 150, height: 150}} />
             <Typography variant="h6" sx={{ color: '#1c1c1e', mb: 1, fontSize:"12px" }}>Imagem de Perfil</Typography>
            </Box>
  
            <Box sx={{ display: 'flex', gap: 2, mb: 2, flexDirection:"column" }}>
             <img src={userDataSingle?.image_source} alt=""   style={{ width: 300, height: 150}} />
             <Typography variant="h6" sx={{ color: '#1c1c1e', mb: 1, fontSize:"12px" }}>Confirmacão de identidade</Typography>
            </Box>
          </div>
        </Box>
      <Divider sx={{ my: 2 }} />
        {/* Informação Pessoal */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ color: '#1c1c1e', fontWeight: '500', mb: 1 }}>
            Informação Pessoal
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                Data de Nascimento
              </Typography>
              <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                {userDataSingle?.birth_date}
              </Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                Telefone
              </Typography>
              <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                {userDataSingle?.phone}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                Província
              </Typography>
              <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                {userDataSingle?.province}
              </Typography>
            </Box>
            <Box sx={{ flex: 1 }}>
              <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                Município
              </Typography>
              <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                {userDataSingle?.street}
              </Typography>

              <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                Email
              </Typography>
              <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                {userDataSingle?.user_id}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography variant="body2" sx={{ color: '#8e8e93' }}>
              Apresentação
            </Typography>
            <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
              {userDataSingle?.description}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 2 }} />

      {/* Comunicação e Comprovantes */}
       <Accordion sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" sx={{ color: '#1c1c1e', fontWeight: '500' }}>
              Comunicação 
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Caixa de texto para enviar mensagem */}
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                placeholder="Escreva uma mensagem..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                onClick={handleSendMessage}
                sx={{ backgroundColor: '#007AFF', color: '#ffffff' }}
              >
                Enviar Mensagem
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>


        <Accordion sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" sx={{ color: '#1c1c1e', fontWeight: '500' }}>
            Comprovantes de Pagamento
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
           

            {/* Seção de arquivos (comprovantes de pagamento) */}
            <Box>
             
              <List>
                {['comprovante1.pdf', 'comprovante2.pdf'].map((proof, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={proof} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* Experiência de Trabalho */}
        <Accordion sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" sx={{ color: '#1c1c1e', fontWeight: '500' }}>
              Experiência de Trabalho
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                  Ocupação
                </Typography>
                <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                  {userDataSingle?.occupation}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                  Trabalho
                </Typography>
                <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                  {userDataSingle?.job_title}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                  Início
                </Typography>
                <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                  {userDataSingle?.job_start_date}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                  Fim
                </Typography>
                <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                  {userDataSingle?.job_end_date}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ my: 2 }} />

        {/* Educação */}
        <Accordion sx={{ mb: 2 }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6" sx={{ color: '#1c1c1e', fontWeight: '500' }}>
              Educação
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                  Instituição
                </Typography>
                <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                  {userDataSingle?.institution}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                  Curso
                </Typography>
                <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                  Curso de Matemática
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                  Início
                </Typography>
                <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                  {userDataSingle?.school_start_date}
                </Typography>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Typography variant="body2" sx={{ color: '#8e8e93' }}>
                  Fim
                </Typography>
                <Typography variant="body1" sx={{ color: '#1c1c1e' }}>
                  {userDataSingle?.school_end_date}
                </Typography>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>

        <Divider sx={{ my: 2 }} />

        {/* Habilidades */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ color: '#1c1c1e', fontWeight: '500', mb: 1 }}>
            Habilidades/Disciplinas de Domínio
          </Typography>
          <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            {userDataSingle?.skills?.map((skill, index) => (
              <Chip key={index} label={skill.title} sx={{ backgroundColor: '#e0e0e0', color: '#1c1c1e' }} />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
      {/* <Button onClick={sendmessageReponse} sx={{ color: '#1c1c1e' }}>
          Enviar Mensagem
        </Button> */}
        <Button onClick={handleClose} sx={{ color: '#1c1c1e' }}>
          Fechar
        </Button>
        <Button onClick={aproveInvite} variant="contained" sx={{ backgroundColor: '#007AFF', color: '#ffffff' }}>
          Aprovar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DetailDialog;