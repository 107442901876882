import React from 'react';
import logo from '../images/Eureca_Logo_Branco.png';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Ícone de lua (modo escuro)
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Ícone de sol (modo claro)
import { Link } from 'react-router-dom';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    CssBaseline,
    Box,
    Container,
    Paper,
    TextField,
    Select,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    createTheme,
    ThemeProvider,
  } from '@mui/material';

function Sidebar(props) {

    const {darkMode,toggleTheme,drawerOpen,setDrawerOpen} = props;
    const paths =['/', '/admin/plans', '/admin/sales', '/admin/custom'] 

    return (
        <div>
                <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={()=>setDrawerOpen(true)}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              Dashboard
            </Typography>
            {/* Botão de ícone para alternar o tema */}
            <IconButton color="inherit" onClick={toggleTheme}>
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={()=>setDrawerOpen(false)}
          sx={{
            width: 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 240,
              boxSizing: 'border-box',
              backgroundColor: darkMode ? '#1e1e1e' : '#ffffff',
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <List>
              {['Início', 'Planos', 'Vendas', 'Customizacoes'].map((text, index) => (
                <Link to={paths[index]} className="navbar-expand-xl">
                <ListItem button key={text}>
                  <ListItemText primary={text} sx={{ color: darkMode ? '#ffffff' : '#000000' }} />
                </ListItem></Link>
              ))}
            </List>
          </Box>
        </Drawer>
        </div>
    //  <nav className="navbar top-bar navbar-light border-bottom py-0 py-xl-3 bg-dark">
    //  <div className="container-fluid p-0">
    //      <div className="d-flex align-items-center w-100">

    //      <Link to={"/"} className="navbar-expand-xl">
    //         <img src={logo} width={100} height={50}/>
    //      </Link>

    //      {/* Top bar left */}
    //      <div className="navbar-expand-lg ms-auto ms-xl-0" style={{visibility:"hidden"}}>
    //          {/* Toggler for menubar START */}
    //          <button
    //          className="navbar-toggler ms-auto"
    //          type="button"
    //          data-bs-toggle="collapse"
    //          data-bs-target="#navbarTopContent"
    //          aria-controls="navbarTopContent"
    //          aria-expanded="false"
    //          aria-label="Toggle navigation"
    //          >
    //          <span className="navbar-toggler-animation">
    //              <span />
    //              <span />
    //              <span />
    //          </span>
    //          </button>
    //          {/* Toggler for menubar END */}
    //          {/* Topbar menu START */}
    //          <div
    //          className="collapse navbar-collapse w-100"
    //          id="navbarTopContent"
    //          >
    //          {/* Top search START */}
    //          <div className="nav my-3 my-xl-0 flex-nowrap align-items-center">
    //              <div className="nav-item w-100">
    //              <form className="position-relative">
    //                  <input
    //                  className="form-control pe-5 bg-secondary bg-opacity-10 border-0"
    //                  type="search"
    //                  placeholder="Search"
    //                  aria-label="Search"
    //                  />
    //                  <button
    //                  className="bg-transparent px-2 py-0 border-0 position-absolute top-50 end-0 translate-middle-y"
    //                  type="submit"
    //                  >
    //                  <i className="fas fa-search fs-6 text-primary" />
    //                  </button>
    //              </form>
    //              </div>
    //          </div>
    //          {/* Top search END */}
    //          </div>
    //          {/* Topbar menu END */}
    //      </div>
    //      {/* Top bar left END */}
    //      {/* Top bar right START */}
    //      <div className="ms-xl-auto">
    //          <div className="navbar-nav flex-row align-items-center">
    //             <Link to={"/"}className="nav-item ms-2 ms-md-3 text-white">Home</Link>
    //             <Link to={"/admin/plans"}  className="nav-item ms-2 ms-md-3 text-white">Planos</Link>
    //             <Link to={"/admin/custom"} className="nav-item ms-2 ms-md-3 text-white">Personalizacão</Link>
    //          </div>
    //      </div>
    //      {/* Top bar right END */}
    //      </div>
    //  </div>
    //  </nav>
    );
}

export default Sidebar;