import { jwtDecode } from "jwt-decode";

export function getDecodedToken(){
    if(!localStorage.key("token") || !localStorage.getItem("token")){
      // localStorage.clear();
      return "error";
    }

    const base_user_info = localStorage.getItem("token")?jwtDecode(localStorage.getItem("token")):{};
    return base_user_info;
}
