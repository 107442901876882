import { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Toolbar,useMediaQuery,Badge 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DetailDialog from './DetailDialog';
import Network from '../network/networkRequests';

function Main(props) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [userData, setUserData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [applicationID, setApplicationID] = useState({});

  const { darkMode } = props;

  async function getUsers() {
    try {
      const response = await Network.getUsersData({});
      if (response.data.error) {
        console.log(response.data.error);
        window.localStorage.clear();
        return;
      }
      setUserData(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 2, backgroundColor: darkMode ? '#121212' : '#f5f5f5' }}>
      <Toolbar />
      <Container maxWidth="lg">
        <Typography variant="h4" gutterBottom sx={{ color: darkMode ? '#fff' : '#000', textAlign: 'center' }}>
          Dashboard
        </Typography>

        {/* Cards */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 2, mb: 4 }}>
          {[{ label: 'Total de Alunos', value: 1200 }, { label: 'Total de Explicadores', value: 996 }, { label: 'Total de Aulas', value: 200 }].map((item, index) => (
            <Paper key={index} sx={{ p: 2, minWidth: 120, textAlign: 'center', flex: '1 1 150px', backgroundColor: darkMode ? '#1e1e1e' : '#fff' }}>
              <Typography variant="h6" sx={{ color: darkMode ? '#fff' : '#000' }}>{item.label}</Typography>
              <Typography variant="h4" sx={{ color: darkMode ? '#fff' : '#000' }}>{item.value}</Typography>
            </Paper>
          ))}
        </Box>

        {/* Pesquisa e Filtro */}
        <Paper sx={{ p: 2, mb: 2, backgroundColor: darkMode ? '#1e1e1e' : '#fff' }}>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Pesquisar"
              InputProps={{
                startAdornment: <SearchIcon sx={{ color: darkMode ? '#b3b3b3' : '#757575' }} />,
                sx: { backgroundColor: darkMode ? '#121212' : '#f5f5f5', color: darkMode ? '#fff' : '#000' },
              }}
            />
            <Select
              fullWidth
              variant="outlined"
              defaultValue=""
              displayEmpty
              sx={{ backgroundColor: darkMode ? '#121212' : '#f5f5f5', color: darkMode ? '#fff' : '#000' }}
            >
              <MenuItem value="">Filtrar por</MenuItem>
              <MenuItem value="recente">Recente</MenuItem>
              <MenuItem value="antigo">Antigo</MenuItem>
              <MenuItem value="aceito">Aceito</MenuItem>
              <MenuItem value="rejeitado">Rejeitado</MenuItem>
            </Select>
          </Box>
        </Paper>

        {/* Tabela */}
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Pedidos Recebidos</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            <TableBody >
                    {userData.map((value, key) => (
                    
                       <TableRow key={key}>
                        <TableCell>
                          
                          <Box sx={{ display: 'flex', alignItems: 'center', width:"30vw"}}>
                          <Typography variant="body2" sx={{ color: darkMode ? '#b3b3b3' : '#757575' }} className='p-3'>
                              {value.id}
                              </Typography>
                            <img
                              src={value.profileImage}
                              alt="avatar"
                              style={{ width: 40, height: 40, borderRadius: '50%', marginRight: 10 }}
                            />
                            <Typography sx={{ color: darkMode ? '#ffffff' : '#000000' }}>{value.nome} {value.ultimoNome}</Typography>
                          </Box>
                        </TableCell>
                        {!isMobile && <>
                          <TableCell sx={{ color: darkMode ? '#ffffff' : '#000000' }}>{value.instructor?.ocupacao ?? "Nenhuma"}</TableCell>
                          <TableCell sx={{ color: darkMode ? '#ffffff' : '#000000' }}>{value.data}</TableCell>
                          <TableCell>
                            <Typography variant="body2" sx={{ color: darkMode ? '#b3b3b3' : '#757575' }}>
                              {value.instructor?.genero ?? "Indefinido"}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ color: darkMode ? '#ffffff' : '#000000' }}>{value.price}.00 Kzs</TableCell>
                          <TableCell>
                            {value.featured == 1 ? (
                              <Typography variant="body2" sx={{ color: '#ffcc00' }}>
                                Em Análise
                              </Typography>
                            ) : value.featured == 2 ? (
                              <Typography variant="body2" sx={{ color: '#00cc66' }}>
                                Aprovado
                              </Typography>
                            ) : (
                              <Typography variant="body2" sx={{ color: darkMode ? '#b3b3b3' : '#757575' }}>
                           
                              </Typography>
                            )}
                          </TableCell>
                        </>}
                        <TableCell>
                        <Badge badgeContent={4} color="primary">
                          <Button
                            variant="outlined"
                            sx={{ color: darkMode ? '#ffffff' : '#000000', borderColor: darkMode ? '#ffffff' : '#000000' }}
                            onClick={() => {
                              setOpenDialog(true);
                              setApplicationID(value);
                            }}
                          >Ver Aplicação</Button>
                          </Badge>
                        </TableCell>
                      </TableRow>
                  
                    ))}
                  </TableBody>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      {openDialog && ( <DetailDialog open={openDialog} handleClose={() => setOpenDialog(false)} appID={applicationID} />)}
     
    </Box>
  );
}

export default Main;